/* eslint-disable eqeqeq */
import React, { useState } from "react";
import "./ResourceSearch.css";
import ResourceCard from "../../Reusable/ResourceCard/ResourceCard";
import dummy from "../../../assests/robot-handshake-human-background-futuristic-digital-age.jpg";
import dummy1 from "../../../assests/team-working-by-group-video-call-share-ideas-brainstorming-negotiating-use-video-conference_482257-5133.jpg";
import dummy2 from "../../../assests/people-with-laptops-office[1].jpg";
import dummy3 from "../../../assests/customer-marketing-sales-dashboard-graphics-concept_53876-123735.jpg";
import blog5 from "../../../assests/blog5.jpg";
import How_On_Demand_Hiring_Works from "../../../assests/How_On_Demand_Hiring_Works.jpg";
import How_do_you_cater_to_Gen_Z_ways_of_working from "../../../assests/How do you cater to Gen Z ways of working.jpg";
import How_to_Assess_Cultural_Fit_of_On_Demand_Talent from "../../../assests/How_to_Assess_Cultural_Fit_of_On_Demand_Talent V1.jpg";
import Attracting_Top_Tech_Talent_in_a_Competitive_Market from "../../../assests/Attracting Top Tech Talent in a Competitive Market V1.jpg";
import Best_Programming_Languages_to_Learn from "../../../assests/Best Programming Languages to Learn.jpg";
import { useDispatch } from "react-redux";
import { storeAction } from "../../../Store/Store";

const ResourceSearch = () => {
  const dispatch = useDispatch();
  const [select, setSelect] = useState("All");
  const [loadmore, setloadmore] = useState(false);
  const SelectHandler = (e) => {
    setSelect(e);
  };
  function toggle(cart) {
    console.log(cart, "cart");
    dispatch(storeAction.cartitemHandler({ cartitem: cart }));
    if (cart == "cart1") {
      window.open(
        "/blog/hiring-a-generative-ai-team-what-you-need-to-know",
        "_blank"
      );
    } else if (cart == "cart2") {
      window.open(
        "/blog/a-quick-guide-to-set-up-remote-team-for-your-software-development-projects",
        "_blank"
      );
    } else if (cart == "cart3") {
      window.open(
        "/blog/hiring-house-vs-professional-platforms-like-hirein5",
        "_blank"
      );
    } else if (cart == "cart4") {
      window.open("/blog/understanding-recruitment-trends", "_blank");
    } else if (cart == "cart5") {
      window.open("/blog/hiring-fast-paced-industries", "_blank");
    } else if (cart == "cart6") {
      window.open("/blog/How-On-Demand-Hiring-Works", "_blank");
    } else if (cart == "cart7") {
      window.open("/blog/How-do-you-cater-to-Gen-Z-ways-of-working", "_blank");
    }
  }
  return (
    <div>
      <div className="resourceSearch sectionWidth">
        <div className="resourceSearchComp mainbody">
          {/* <div className="resourceSearchContent">
            <input type="text" />
            <select className="webHandler" name="" id="">
              <option>Filter by Categories</option>
            </select>
            <button className="filterButton mobileHandler">
              <img src={filterImg} alt="" />
            </button>
            <FiSearch className="searchIcon" />
          </div> */}
          <div className="resourceSearchFilterComp">
            <div className="resourceSearchFilter">
              <h5
                onClick={() => SelectHandler("All")}
                className={
                  select == "All"
                    ? "resourceSearchFilterBubbleActive"
                    : "resourceSearchFilterBubble"
                }
              >
                All Blogs
              </h5>
              <h5
                onClick={() => SelectHandler("Hiringresourse")}
                className={
                  select == "Hiringresourse"
                    ? "resourceSearchFilterBubbleActive"
                    : "resourceSearchFilterBubble"
                }
              >
                Hiring Resources
              </h5>
              <h5
                onClick={() => SelectHandler("Remote_Hiring")}
                className={
                  select == "Remote_Hiring"
                    ? "resourceSearchFilterBubbleActive"
                    : "resourceSearchFilterBubble"
                }
              >
                Remote Hiring
              </h5>
              <h5
                onClick={() => SelectHandler("Software_Developers")}
                className={
                  select == "Software_Developers"
                    ? "resourceSearchFilterBubbleActive"
                    : "resourceSearchFilterBubble"
                }
              >
                Software Developers
              </h5>
              <h5
                onClick={() => SelectHandler("GCC")}
                className={
                  select == "GCC"
                    ? "resourceSearchFilterBubbleActive"
                    : "resourceSearchFilterBubble"
                }
              >
                GCC
              </h5>
              <h5
                onClick={() => SelectHandler("On-Demand Talent")}
                className={
                  select == "On-Demand Talent"
                    ? "resourceSearchFilterBubbleActive"
                    : "resourceSearchFilterBubble"
                }
              >
                On-Demand Talent
              </h5>
            </div>
          </div>
        </div>
        <div className="resourceSearchCard mainbody">
          <ResourceCard
            title="Hiring A Generative AI team: Here’s what you need to know"
            desc="We all are aware of Artificial Intelligence and its evolving landscape. With the emergence of Generative AI, it has opened new possibilities and challenges for businesses looking to leverage this technology."
            Img={dummy}
            onlearnmore={() => toggle("cart1")}
            author="Team Hirein5"
            date="May 25, 2024"
            read="5 MINUTE READ"
            type="BLOG"
          />
          <ResourceCard
            title="A quick guide to set-up remote team for your software development projects"
            desc="Remote software development teams have become a viable and highly efficient solution for organizations looking to optimize their software development process. Offshore teams allow you to quickly scale up or down as project needs change, and developers can work on demand without the company incurring expensive relocation costs or hiring permanent staff locally. India has a huge and affordable talent pool of software engineers to help global organizations outsource their software operations. This makes India the perfect place to outsource software development projects. This model offers several advantages, including access to a larger talent pool, lower costs, and increased flexibility."
            Img={dummy1}
            onlearnmore={() => toggle("cart2")}
            author="Team Hirein5"
            date="May 30, 2024"
            read="5 MINUTE READ"
            type="BLOG"
          />
          <ResourceCard
            title="Hiring in-house Vs Professional platforms like Hirein5"
            desc="In the digital evolution race, companies can no longer afford to stay behind because of lack of talent. Hence, hiring managers and talent acquisition teams are always under pressure to close the requirements. But, traditional ways are succumbing to this pressure."
            Img={dummy2}
            onlearnmore={() => toggle("cart3")}
            author="Team Hirein5"
            date="June 03, 2024"
            read="5 MINUTE READ"
            type="BLOG"
          />
          {loadmore && (
            <>
              <ResourceCard
                title="Understanding Recruitment Trends"
                desc="In today's dynamic business landscape, organizations must stay current on recruitment trends to attract and retain top talent."
                Img={dummy3}
                onlearnmore={() => toggle("cart4")}
                author="Team Hirein5"
                date="June 10, 2024"
                read="3 MINUTE READ"
                type="BLOG"
              />
              <ResourceCard
                title="Hiring in fast paced industries: what to do"
                desc="Business Teams today are agile and crucial to meeting changing business needs of any company. Especially when most new businesses are aspiring to grow globally; revenue, transformation, digitalisation and market performance are factors that play a role in hiring and selecting high performing  teams. And finding the right talent to achieve the desired business outcomes is the most critical component for success in these rapidly changing times."
                Img={blog5}
                onlearnmore={() => toggle("cart5")}
                author="Team Hirein5"
                date="June 15, 2024"
                read="8 MINUTE READ"
                type="BLOG"
              />
              {/* <ResourceCard
                title="How On-Demand Hiring Works"
                desc="Companies across the world are continuously looking for innovative ways to remain flexible & competitive in today’s fast-paced and dynamic business environment. “On-Demand Hiring” or “Flexible Staffing” has become popular  and essential for companies to tap into the best global talent. On-demand hiring / staffing refers to the practice of recruiting a workforce based on a short term or project basis, instead of hiring and paying for a full-time workforce to fulfill immediate requirements. With the flexibility to quickly scale the workforce in response to the demands, this hiring model frees organizations from the long-term commitments and overheads associated with traditional hiring methods. It provides an edge to Digital Transformation projects and allows companies to fulfill several project demands at the same time rather than waiting for resources to become available or indeed hiring and staffing technology resources on a permanent basis."
                Img={How_On_Demand_Hiring_Works}
                onlearnmore={() => toggle("cart6")}
                author="Team Hirein5"
                date="June 20, 2024"
                read="9 MINUTE READ"
                type="BLOG"
              />
              <ResourceCard
                title="How do you cater to Gen Z ways of working"
                desc="Generation Z were born between 1997 and 2012. They constitute one of the largest workforce currently across the globe. Gen Z has been raised in an era of digital world, having the privileges of technology available to them at their fingertips. They have been witness to global economic and political uncertainties that have shaped their thinking. They value and demand diverse professional  setups, open company cultures and secure work ethics. They have witnessed the surge in Gig Workforce and are adaptable to the changing demands of workplaces. During this time period the technological innovations grew many folds and it certainly had a significant impact on how this young workforce perceives their way of work, career choices, roles, and responsibilities. "
                Img={How_do_you_cater_to_Gen_Z_ways_of_working}
                onlearnmore={() => toggle("cart7")}
                author="Team Hirein5"
                date="June 26, 2024"
                read="9 MINUTE READ"
                type="BLOG"
              />
              <ResourceCard
                title="How to Assess Cultural Fit of On-Demand Talent"
                desc="In recent decades the corporate working style has changed many folds. In today's gig economy, companies recognize the importance of On-Demand teams or skilled freelancers while planning their staffing needs. Adopting a plug-in plug-out approach proves beneficial because these short-term or project-specific teams offer useful approach & abilities with no long-term overhead expenses. But choosing this staffing option brings a set of hurdles and challenges as well because it takes a lot of effort to ensure they mesh well with the company's culture."
                Img={How_to_Assess_Cultural_Fit_of_On_Demand_Talent}
                onlearnmore={() => toggle("cart8")}
                author="Team Hirein5"
                date="July 02, 2024"
                read="9 MINUTE READ"
                type="BLOG"
              />
              <ResourceCard
                title="Attracting Top Tech Talent in a Competitive Market"
                desc="The tech sector is a talent war zone because there is fierce competition for qualified professionals. The top tech talent is able to make a choice on the kind of employer they want to work with. In such a scenario attracting the best talent has become daunting as well as time consuming. There is a global shortage of skilled professionals. In order to overcome this crisis companies must differentiate themselves and develop value propositions for the modern tech workforce which can appeal to them and speak to their needs."
                Img={Attracting_Top_Tech_Talent_in_a_Competitive_Market}
                onlearnmore={() => toggle("cart9")}
                author="Team Hirein5"
                date="July 10, 2024"
                read="9 MINUTE READ"
                type="BLOG"
              />
              <ResourceCard
                title="Best Programming Languages to Learn"
                desc="What is Programming language? A programming language is a set of guidelines & directives that enable people to interact with computers to develop apps and software. Code is written by the programmer that instructs the computers what to do and these codes are written in different languages which serve as a conduit between human concepts and computer commands. "
                Img={Best_Programming_Languages_to_Learn}
                onlearnmore={() => toggle("cart10")}
                author="Team Hirein5"
                date="July 15, 2024"
                read="9 MINUTE READ"
                type="BLOG"
              /> */}
            </>
          )}
        </div>
        {loadmore == false ? (
          <button
            className="loadMore"
            onClick={() => {
              setloadmore(true);
            }}
          >
            Load more
          </button>
        ) : (
          <button
            className="loadMore"
            onClick={() => {
              setloadmore(false);
            }}
          >
            Load less
          </button>
        )}
      </div>
    </div>
  );
};

export default ResourceSearch;
