/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from "react";
import "./JobSeekerBanner.css";
import jobSeekerBannerImg from "../../../assests/JobseekerBanner.svg";
import jobSeekerBannerImgMob from "../../../assests/JobseekerBannerMob.svg";
import AOS from "aos";
import "aos/dist/aos.css";

const JobSeekerBanner = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <div className="jobSeekerContainer">
        <div className="jobSeekerFlex">
          <div className="jobSeekerFlexContent webHandler">
            <h1
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="300"
            >
              Grow your career with Hirein5
            </h1>
            <p
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="500"
            >
              At Hirein5, we strongly believe in human talent as a real 
              differentiator for organizations to thrive.{" "}
            </p>
            <p
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="700"
            >
              We are looking for people who are ready to be part of our growth 
              journey while helping us in changing the future of talent hiring
              and management. 
            </p>
            <a
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="900"
              href="https://app.hirein5.com/#/candidateregister"
              target="_blank"
            >
              <button
                data-aos="fade-right"
                data-aos-duration="1000"
                data-aos-delay="900"
              >
                Apply Now
              </button>
            </a>
          </div>
          <div className="jobSeekerFlexContent mobileHandler">
            <h1>
              Grow your career with<br></br> Hirein5
            </h1>
            <p>
              At Hirein5, we strongly believe in human <br />
              talent as a real differentiator for <br /> organizations to
              thrive.{" "}
            </p>
            <p>
              We are looking for people who are ready to be part of our growth
              journey while helping us in changing the future of talent hiring
              and management. 
            </p>
            <a
              href="https://app.hirein5.com/#/candidateregister"
              target="_blank"
            >
              <button>Apply Now</button>
            </a>
          </div>
          <div className="jobSeekerFlexContent">
            <img
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-delay="1000"
              className="jobSeekerFlexContentImg"
              src={jobSeekerBannerImg}
              alt=""
            />{" "}
            <img
              className="jobSeekerFlexContentImgMob"
              src={jobSeekerBannerImgMob}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobSeekerBanner;
