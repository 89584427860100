/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";
import "./Navbar.css";
import logo from "../../assests/FullLogo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { LuMenu } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import { storeAction } from "../../Store/Store";
import navImageLeft from "../../assests/navLeftImage.svg";
import navImageRight from "../../assests/navRightImage.svg";
import navImageBottom from "../../assests/navBottomImage.svg";
import { AiOutlineHome } from "react-icons/ai";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { IoTrophyOutline } from "react-icons/io5";
import { AiOutlineDollar } from "react-icons/ai";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { TbUsers } from "react-icons/tb";
import { RiFlowChart } from "react-icons/ri";

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const [isActive, setActive] = useState("/");
  const navHandler = (e) => {
    setActive(e);
    navigate(e);
    dispatch(storeAction.isPopUpHander());
  };
  const isPopUp = useSelector((store) => {
    return store.isPopUp;
  });

  const popupHandler = (e) => {
    dispatch(storeAction.isPopUpHander(e));
  };

  const positionAwareHandler = (e) => {
    const x = e.pageX - document.querySelector(`#${e.target.id}`).offsetLeft;
    const y = e.pageY - document.querySelector(`#${e.target.id}`).offsetTop;
    document
      .querySelector(`#${e.target.id}`)
      .style.setProperty("--x", x + "px");
    document
      .querySelector(`#${e.target.id}`)
      .style.setProperty("--y", y + "py");
  };

  return (
    <div>
      <div className="navbar">
        <div className="navbarComp">
          <div className="navLogo">
            <LuMenu
              onClick={() => popupHandler("mobileNav")}
              className="menuIcon"
            />
            <img onClick={() => navHandler("/")} src={logo} alt="" />
          </div>
          <div className="navMenu">
            <h1
              onClick={() => navHandler("/")}
              className={
                location.pathname.slice(0) == "/"
                  ? "navMenuActive"
                  : "navMenuDisable"
              }
            >
              Home
            </h1>
            <h1
              onClick={() => navHandler("about-hirein5")}
              className={
                location.pathname.slice(1) == "about-hirein5"
                  ? "navMenuActive"
                  : "navMenuDisable"
              }
            >
              About Us
            </h1>
            <h1
              onClick={() => navHandler("how-hirein5-works")}
              className={
                location.pathname.slice(1) == "how-hirein5-works"
                  ? "navMenuActive"
                  : "navMenuDisable"
              }
            >
              How it works
            </h1>
            <h1
              onClick={() => navHandler("talent")}
              className={
                location.pathname.slice(1) == "talent"
                  ? "navMenuActive"
                  : "navMenuDisable"
              }
            >
              For Talent
            </h1>
            {/* <h1
              onClick={() => navHandler("pricing")}
              className={
                location.pathname.slice(1) == "pricing"
                  ? "navMenuActive"
                  : "navMenuDisable"
              }
            >
              Pricing
            </h1> */}
            <h1
              onClick={() => navHandler("resources")}
              className={
                location.pathname.slice(1) == "resources"
                  ? "navMenuActive"
                  : "navMenuDisable"
              }
            >
              Resources
            </h1>
            <h1
              onClick={() => navHandler("contact")}
              className={
                location.pathname.slice(1) == "contact"
                  ? "navMenuActive"
                  : "navMenuDisable"
              }
            >
              Contact Us
            </h1>

            <div className="navButton">
              <a
                onMouseMove={positionAwareHandler}
                id="loginBtn"
                className="loginButton webHandler"
                href="https://app.hirein5.com/#/login"
                target="_blank"
              >
                Login
              </a>
              <a
                onMouseMove={positionAwareHandler}
                id="signupBtn"
                className="signButton"
                href="https://app.hirein5.com/#/"
                target="_blank"
              >
                {" "}
                Sign Up
              </a>
            </div>
          </div>
          <a className="registerPage" href="https://app.hirein5.com/#/">
            <button className="signButton">Sign Up</button>
          </a>
        </div>
        <div
          className={
            isPopUp == "mobileNav"
              ? "NavMobileComponentActive overlay"
              : "NavMobileComponent overlay"
          }
        >
          <div className="NavMobileComponentLogo">
            <img className="navImageLeft" src={navImageLeft} alt="" />
            <img className="navImageCenter" src={logo} alt="" />
            <img className="navImageRight" src={navImageRight} alt="" />
          </div>
          <div className="NavMobileComponentMenu">
            <div className="NavMobileComponentBody">
              <div className="NavMobileMenuContent">
                <AiOutlineHome
                  className={
                    location.pathname.slice(1) == ""
                      ? "mobileNavIconActive"
                      : "mobileNavIcon"
                  }
                />
                <h2
                  onClick={() => navHandler("/")}
                  className={
                    location.pathname.slice(1) == ""
                      ? "NavMobileMenuContentTextAcive"
                      : "NavMobileMenuContentText"
                  }
                >
                  Home
                </h2>
              </div>
              <div className="NavMobileMenuContent">
                <IoMdInformationCircleOutline
                  className={
                    location.pathname.slice(1) == "about-hirein5"
                      ? "mobileNavIconActive"
                      : "mobileNavIcon"
                  }
                />
                <h2
                  onClick={() => navHandler("about-hirein5")}
                  className={
                    location.pathname.slice(1) == "about-hiren5"
                      ? "NavMobileMenuContentTextAcive"
                      : "NavMobileMenuContentText"
                  }
                >
                  About Us
                </h2>
              </div>
              <div className="NavMobileMenuContent">
                <RiFlowChart
                  className={
                    location.pathname.slice(1) == "how-hirein5-works"
                      ? "mobileNavIconActive"
                      : "mobileNavIcon"
                  }
                />
                <h2
                  onClick={() => navHandler("how-hirein5-works")}
                  className={
                    location.pathname.slice(1) == "how-hirein5-works"
                      ? "NavMobileMenuContentTextAcive"
                      : "NavMobileMenuContentText"
                  }
                >
                  How it works
                </h2>
              </div>
              <div className="NavMobileMenuContent">
                <IoTrophyOutline
                  className={
                    location.pathname.slice(1) == "talent"
                      ? "mobileNavIconActive"
                      : "mobileNavIcon"
                  }
                />
                <h2
                  onClick={() => navHandler("talent")}
                  className={
                    location.pathname.slice(1) == "talent"
                      ? "NavMobileMenuContentTextAcive"
                      : "NavMobileMenuContentText"
                  }
                >
                  For Talent
                </h2>
              </div>
              {/* <div className="NavMobileMenuContent">
                <AiOutlineDollar
                  className={
                    location.pathname.slice(1) == "pricing"
                      ? "mobileNavIconActive"
                      : "mobileNavIcon"
                  }
                />
                <h2
                  onClick={() => navHandler("pricing")}
                  className={
                    location.pathname.slice(1) == "pricing"
                      ? "NavMobileMenuContentTextAcive"
                      : "NavMobileMenuContentText"
                  }
                >
                  Pricing
                </h2>
              </div> */}
              <div className="NavMobileMenuContent">
                <TbUsers
                  className={
                    location.pathname.slice(1) == "resources"
                      ? "mobileNavIconActive"
                      : "mobileNavIcon"
                  }
                />
                <h2
                  onClick={() => navHandler("resources")}
                  className={
                    location.pathname.slice(1) == "resources"
                      ? "NavMobileMenuContentTextAcive"
                      : "NavMobileMenuContentText"
                  }
                >
                  Resources
                </h2>
              </div>
              <div className="NavMobileMenuContent">
                <AiOutlineQuestionCircle
                  className={
                    location.pathname.slice(1) == "contact"
                      ? "mobileNavIconActive"
                      : "mobileNavIcon"
                  }
                />
                <h2
                  onClick={() => navHandler("contact")}
                  className={
                    location.pathname.slice(1) == "contact"
                      ? "NavMobileMenuContentTextAcive"
                      : "NavMobileMenuContentText"
                  }
                >
                  Contact Us
                </h2>
              </div>
            </div>
            <div className="NavMobileMenuButton">
              <a
                onMouseMove={positionAwareHandler}
                id="signupBtn"
                className="signButton navBarButtonWidth"
                href="https://app.hirein5.com/#/"
                target="_blank"
              >
                {" "}
                Sign Up
              </a>
              <a
                onMouseMove={positionAwareHandler}
                id="loginBtn"
                className="loginButton navBarButtonWidth navBarButtonWidthLogin"
                href="https://app.hirein5.com/#/login"
                target="_blank"
              >
                Login
              </a>
              <img className="navImageBottom" src={navImageBottom} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
