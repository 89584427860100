/* eslint-disable eqeqeq */
import React, { useRef, useState } from "react";
import "./HotJobs.css";
import SectionHeading from "../../Reusable/SectionHeading/SectionHeading";
import JobCard from "../../Reusable/JobCard/JobCard";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { useDispatch, useSelector } from "react-redux";
import { storeAction } from "../../../Store/Store";
import { IoMdClose } from "react-icons/io";
import axios from "axios";

const HotJobs = () => {
  const dispatch = useDispatch();
  const isPopUp = useSelector((store) => {
    return store.isPopUp;
  });

  const closeOverlay = () => {
    dispatch(storeAction.isPopUpHander());
  };
  const [hotjob, sethotjob] = useState("");
  const [resumedata, setresumedata] = useState(false);
  const [loading, setloading] = useState(false);
  const [status, setstatus] = useState(false);

  const fileInputRef = useRef(null);
  const fileInputRef1 = useRef(null);
  const showhandler = (data) => {
    fileInputRef.current.click();
  };
  const showhandler1 = (data) => {
    fileInputRef1.current.click();
  };
  const [formData] = useState(new FormData());
  const handleFileInput_Change = async (e) => {
    setresumedata(false);
    formData.append("resume_attachment", e.target.files[0]);
    formData.append("job_title", `${hotjob}`);
    fileInputRef.current.value = "";
    setresumedata(true);
  };
  const handleFileInput_Change1 = async (e) => {
    setresumedata(false);
    formData.append("resume_attachment", e.target.files[0]);
    formData.append("job_title", `Relevant Opportunities`);
    fileInputRef1.current.value = "";
    setresumedata(true);
    const response = await axios
      .post("https://devapi.hirein5.com/pdf/email/", formData)
      .then((res) => {
        return res.data;
      });
    if (response.message == "Email sent successfully.") {
      setTimeout(() => {
        setstatus(true);
      }, 2000);
      setTimeout(() => {
        setstatus(false);
        setloading(false);
        setresumedata(false);
        dispatch(storeAction.isPopUpHander());
      }, 8000);
    }
  };
  const submitbtn = async () => {
    setloading(true);
    const response = await axios
      .post("https://devapi.hirein5.com/pdf/email/", formData)
      .then((res) => {
        return res.data;
      });
    if (response.message == "Email sent successfully.") {
      setTimeout(() => {
        setstatus(true);
      }, 2000);
      setTimeout(() => {
        setstatus(false);
        setloading(false);
        setresumedata(false);
        dispatch(storeAction.isPopUpHander());
      }, 8000);
    }
  };
  return (
    <div>
      <div className="hotJobsContainer">
        <SectionHeading
          head1="Uncover Hot Job Opportunities"
          head2=""
          desc1="Break free from the ordinary and ignite your tech career with Hirein5. We're not just matching skills; we're orchestrating your next big move. Explore roles that promise excitement, growth, and continuous learning."
          desc2=""
          desc3=""
          descMob="Break free from the ordinary and ignite your tech career with Hirein5. We're not just matching skills; we're orchestrating your next big move. Explore roles that promise excitement, growth, and continuous learning."
        />
        <div className="hotJobsCardContainer sectionWidthx">
          <JobCard
            Team="Fullstack Engineer"
            Role="Engineering Team"
            Location="Remote"
            Available="Full-time"
            sethotjob={sethotjob}
            Skills={[
              {
                skillName: "JavaScript",
              },
              {
                skillName: "React",
              },
              {
                skillName: "Node.js",
              },
              {
                skillName: "API Development",
              },
              {
                skillName: "Redux",
              },
            ]}
          />
          <JobCard
            Team="Cloud Engineer"
            Role="Engineering Team"
            Location="Remote"
            Available="Full-time"
            sethotjob={sethotjob}
            Skills={[
              {
                skillName: "AWS",
              },
              {
                skillName: "Azure",
              },
              {
                skillName: "Google Cloud Platform",
              },
              {
                skillName: "Terraform",
              },
              {
                skillName: "Kubernetes",
              },
            ]}
          />
          <JobCard
            Team="Cloud Engineer"
            Role="Engineering Team"
            Location="Remote"
            Available="Full-time"
            sethotjob={sethotjob}
            Skills={[
              {
                skillName: "Network Security",
              },
              {
                skillName: "Threat Detection",
              },
              {
                skillName: "Incident Response",
              },
              {
                skillName: "SIEM",
              },
              {
                skillName: "Penetration Testing",
              },
              {
                skillName: "Vulnerability Assessment",
              },
              {
                skillName: "SOC",
              },
              {
                skillName: "NOC",
              },
            ]}
          />
          <JobCard
            Team="DevOps Engineer"
            Role="DevOps Team"
            Location="Remote"
            Available="Full-time"
            sethotjob={sethotjob}
            Skills={[
              {
                skillName: "Discover",
              },
              {
                skillName: "Interview",
              },
              {
                skillName: "Contracts",
              },
              {
                skillName: "Billing",
              },
              {
                skillName: "Help & FAQs",
              },
            ]}
          />
        </div>
        <div className="swiperContent webHandler">
          <Swiper
            slidesPerView={3}
            spaceBetween={20}
            navigation={true}
            modules={[Navigation]}
            className="mySwiper"
            breakpoints={{
              // When the width is >= 640px (tablet view)
              640: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              // When the width is >= 1024px (desktop view)
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
          >
            <SwiperSlide>
              <JobCard
                Team="Fullstack Engineer"
                Role="Engineering Team"
                Location="Remote"
                Available="Full-time"
                sethotjob={sethotjob}
                Skills={[
                  {
                    skillName: "JavaScript",
                  },
                  {
                    skillName: "React",
                  },
                  {
                    skillName: "Node.js",
                  },
                  {
                    skillName: "API Development",
                  },
                  {
                    skillName: "Redux",
                  },
                ]}
              />
            </SwiperSlide>
            <SwiperSlide>
              <JobCard
                Team="Cloud Engineer"
                Role="Engineering Team"
                Location="Remote"
                Available="Full-time"
                sethotjob={sethotjob}
                Skills={[
                  {
                    skillName: "AWS",
                  },
                  {
                    skillName: "Azure",
                  },
                  {
                    skillName: "Google Cloud Platform",
                  },
                  {
                    skillName: "Terraform",
                  },
                  {
                    skillName: "Kubernetes",
                  },
                ]}
              />
            </SwiperSlide>
            <SwiperSlide>
              <JobCard
                Team="Cyber Security Analyst"
                Role="Security Team"
                Location="Remote"
                Available="Full-time"
                sethotjob={sethotjob}
                Skills={[
                  {
                    skillName: "Network Security",
                  },
                  {
                    skillName: "Threat Detection",
                  },
                  {
                    skillName: "Incident Response",
                  },
                  {
                    skillName: "SIEM",
                  },
                  {
                    skillName: "Penetration Testing",
                  },
                  {
                    skillName: "Vulnerability Assessment",
                  },
                  {
                    skillName: "SOC",
                  },
                  {
                    skillName: "NOC",
                  },
                ]}
              />
            </SwiperSlide>
            <SwiperSlide>
              <JobCard
                Team="DevOps Engineer"
                Role="DevOps Team"
                Location="Remote"
                Available="Full-time"
                sethotjob={sethotjob}
                Skills={[
                  {
                    skillName: "CI/CD",
                  },
                  {
                    skillName: "Ansible",
                  },
                  {
                    skillName: "Terraform",
                  },
                  {
                    skillName: "Docker",
                  },
                  {
                    skillName: "Kubernetes",
                  },
                  {
                    skillName: "Azure",
                  },
                  {
                    skillName: "GCP",
                  },
                ]}
              />
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="hotJobsContainerButton">
          <button onClick={showhandler1}>
            Not found a relevant job opening? Just drop your resume
            <span>here</span>
          </button>
          {status && (
            <p className="successtext">
              "Congratulations! Your resume has been successfully submitted.
              Thank you for your application. We'll review it carefully and be
              in touch soon."
            </p>
          )}
        </div>
        <input
          type="file"
          ref={fileInputRef1}
          style={{ display: "none" }}
          name="resume"
          accept=".pdf, .doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          onChange={handleFileInput_Change1}
        />
        {isPopUp == "applynow" && (
          <div className="applyNowOverlay overlay">
            <div className="applyNowOverlayHead">
              <h1>Apply Now</h1>
              <IoMdClose onClick={closeOverlay} className="closeIcon" />
            </div>
            <div className="applyNowOverlayBody">
              <h2>
                Upload your Resume for{" "}
                {hotjob.length !== 0 ? (
                  <span className="hotjob">{hotjob}</span>
                ) : null}
              </h2>
              <div className="upload" onClick={showhandler}>
                <h4>
                  Drop your Resume <span>Browser</span>
                </h4>
                <h3>Only pdf less than 5Mb</h3>
              </div>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                name="resume"
                accept=".pdf, .doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                onChange={handleFileInput_Change}
              />
              {status && (
                <p className="successtext">
                  "Congratulations! Your resume has been successfully submitted.
                  Thank you for your application. We'll review it carefully and
                  be in touch soon."
                </p>
              )}
              {resumedata && (
                <div className="submitButton">
                  {loading === true ? (
                    <button>Please wait...</button>
                  ) : (
                    <button onClick={submitbtn}>Submit</button>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HotJobs;
